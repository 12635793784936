<app-web-header></app-web-header>
<div class="self-guided-trips overflow-hidden position-absolute">
    <div id="carouselExampleInterval" class="carousel parallax slide carousel-fade" data-bs-ride="carousel"
        data-bs-interval="3000">
        <div class="carousel-inner">
            <div class="carousel-item active position-relative">
                <img loading="lazy" [src]="'/home/carousel-1.webp' | linkPrefix" alt="...">
                <div class="carousel-caption ">
                    <h1 class="fsize-18 TTRegular-font">DISCOVER</h1>
                    <br>
                    <p class="titlefont font-50 text-white my-2 showtext">local experiences </p>
                    <div class="subtitlebox position-absolute w-100">
                        <div class="container">
                            <button class="pink-orangebtn text-white common-button " (click)="goToTabs()">ALL
                                EXPERIENCES</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item position-relative">
                <img loading="lazy" [src]="'/home/caousel-2.webp' | linkPrefix" alt="...">
                <div class="carousel-caption ">
                    <h1 class="fsize-18 TTRegular-font">ACTIVITIES </h1>
                    <br>
                    <p class="titlefont font-50 text-white my-2 showtext">beyond the ordinary</p>
                    <div class="subtitlebox position-absolute w-100">
                        <div class="container">
                            <button class="pink-orangebtn text-white common-button " (click)="goToTabs()">ALL
                                ADVENTURES</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item position-relative">
                <img loading="lazy" [src]="'/New Images/HomepageSlider3.webp' | linkPrefix" alt="...">
                <div class="carousel-caption ">
                    <h1 class="fsize-18 TTRegular-font">PLACES</h1>
                    <br>
                    <p class="titlefont font-50 text-white my-2 showtext">off the beaten track</p>
                    <div class="subtitlebox position-absolute w-100">
                        <div class="container">
                            <button class="pink-orangebtn text-white common-button " (click)="goToTabs()">ALL
                                DESTINATIONS</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev opacity-0" type="button" data-bs-target="#carouselExampleInterval"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next opacity-0" type="button" data-bs-target="#carouselExampleInterval"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
    <div class="row  learn-to-live position-relative  my-md-4 pt-md-5 justify-content-center ">
        <div class="col-lg-6 text-center  ">
            <img loading="lazy" [src]="'/home/pink-img.webp' | linkPrefix"
                class="position-absolute pink-line d-block d-md-none" alt="">
            <img loading="lazy" [src]="'/home/blue-line.webp' | linkPrefix"
                class="position-absolute blue-line  d-block d-md-none" alt="">
            <img loading="lazy" [src]="'/home/logo_svg.png' | linkPrefix" class="logo d-none d-md-inline showtext"
                alt="">
            <img loading="lazy" [src]="'/home/logo_svg.png' | linkPrefix" class="logo d-inline d-md-none showtext"
                alt="">
            <h2 class="font-40 TTRegular-font light-black" data-aos="zoom-in" data-aos-duration="800"><b>STRODE</b>
            </h2>
            <p class="TTRegular-font fsize-18 light-black">LEARN TO LIVE AGAIN</p>
            <div class="py-5 px-4 px-md-0">
                <p class="futura-book-font fsize-16 line-height2 light-black">Travel isn't rocket science nor secret
                    technology. Today, listicles on the internet tells you how to explore when you're on a holiday, but
                    they rarely ask how you want to feel. For us, every experience we offer begins with an emotion. What
                    moves you to tears? Who makes you laugh? What compels you to think?</p>
                <p class="futura-book-font fsize-16 line-height2 light-black my-4">We are as concerned with the highest
                    drama as we are with the smallest moments while curating these local experiences. We don’t just want
                    you to go there and do that. We want you to feel it, live it and be gently transformed by travel.
                    Every experience we offer is either personally hosted by our network of young and super friendly
                    local hosts or self guided using our smart, traveller-friendly e-bikes.</p>
                <p class="futura-book-font fsize-16 line-height2 light-black my-4">Choose from our thoughtfully tailored
                    tours and trails, activities and adventures. A planet-loving, adventure-seeking community of
                    Strodesters is geared up to welcome you.<br class="d-none d-md-block"> Hop on and discover the world
                    on your own terms.</p>
            </div>
        </div>
        <div class="position-absolute dotted-linecover  w-100 h-100"></div>
        <img loading="lazy" [src]="'/element/dottedline1.svg' | linkPrefix"
            class="position-absolute dotted-line  d-none d-md-block" alt="">
        <img loading="lazy" [src]="'/element/bike.svg' | linkPrefix" class="position-absolute bike  d-none d-md-block"
            alt="">
    </div>
    <div class="row justify-content-center our-style" id="destination">
        <div class="text-center">
            <p class="TTRegular-font fsize-18 light-black">ADVENTURES DONE DIFFERENTLY</p>
            <h3 class="font-50 titlefont mb-md-4" data-aos="zoom-in" data-aos-duration="800">our destinations</h3>
        </div>
        <div class="col-lg-8 mt-md-5 d-none" id="category">
            <div class="d-flex justify-content-md-center category-list">
                <div *ngFor="let cat of category" [ngClass]="activeCategory==cat?'active-category':''"
                    class="px-md-4 px-3 py-4  text-center category-box" (click)="changeCategory(cat)">
                    <h3 class="TTRegular-font fsize-16">{{cat}}</h3>
                </div>
            </div>
        </div>
        <div class="col-lg-8 mt-5 d-none" id="categoryformobile">
            <div class="d-flex justify-content-md-center category-list">
                <div *ngFor="let cat of category" [ngClass]="activeCategory==cat?'active-category':''"
                    class="px-md-4   text-center category-box" (click)="changeCategory(cat)">
                    <h3 class="TTRegular-font fsize-16">{{cat}}</h3>
                </div>
            </div>
        </div>
        <div class="col-lg-10 d-none d-md-block my-2 destination" data-aos="zoom-out-up"
            *ngIf="activeCategory=='DESTINATIONS'">
            <div class="row">
                <div class="col-lg-6 p-3 text-center position-relative cursor-pointer" *ngFor="let ele of destinations"
                    (click)="redirectToCity(ele.city.toLowerCase())">
                    <img loading="lazy" [src]="ele.img" alt="">
                    <h3 class="TTRegular-font fsize-20 fw-bold position-absolute text-white title">
                        {{ele.city.toUpperCase()}}</h3>
                </div>
            </div>
        </div>
        <div class="col-lg-10 d-md-none d-block my-2 destination" *ngIf="activeCategory=='DESTINATIONS'">
            <div class="d-flex flex-nowrap justify-content-start overflow-auto hidescrollbar"
                style=" white-space: nowrap;">
                <div class="col-10 ps-2 text-center position-relative" *ngFor="let ele of destinations;let i=index;"
                    (click)="redirectToCity(ele.city.toLowerCase())">
                    <img loading="lazy" [src]="ele.img" alt="">
                    <h3 class="TTRegular-font fsize-20 fw-bold position-absolute text-white title">
                        {{ele.city.toUpperCase()}}</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="row my-md-5 pt-md-5 mt-5 mt-md-0 pt-2 our-ethos justify-content-center">
        <div class="text-center mt-md-5">
            <p class="TTRegular-font fsize-18 light-black opacity9">EVERYTHING REVOLVES AROUND </p>
            <h3 class="font-50 titlefont" data-aos="zoom-in" data-aos-duration="800">our ethos</h3>
        </div>
        <div class="col-lg-8 my-md-3 my-4 py-4 d-flex flex-md-row flex-column justify-content-evenly position-relative"
            data-aos="zoom-in-up">
            <img loading="lazy" [src]="'/home/trangle.webp' | linkPrefix"
                class="triangle-img d-none d-md-inline position-relative" alt="">
            <div class="px-1 text-center mt-3 mt-md-0">
                <img loading="lazy" [src]="'/home/onefor.webp' | linkPrefix" class="w-100 cover" alt="">
                <h3 class="fsize-20 my-4 py-2 TTRegular-font light-black " data-aos="fade-up">One for Everyone</h3>
                <p class="futura-book-font fsize-16 light-black my-4 px-md-0 px-3" data-aos="fade-up">Crafting
                    experiences with a <br class="d-none d-md-block"> unique touch for the varied tastes <br
                        class="d-none d-md-block"> you may have. </p>
            </div>
            <div>
            </div>
            <div class="px-1 text-center mt-5 mt-md-0">
                <img loading="lazy" [src]="'/home/local-connection.webp' | linkPrefix" class="w-100 cover" alt="">
                <h3 class="fsize-20 my-4 py-2 TTRegular-font light-black " data-aos="fade-up">Local Connections</h3>
                <p class="futura-book-font fsize-16 light-black my-4 px-md-0 px-3" data-aos="fade-up">Make friends with
                    our hosts who tell <br class="d-none d-md-block"> you those local facts, stories and <br
                        class="d-none d-md-block"> share laughs.</p>
            </div>
            <div>
            </div>
            <div class="px-1 text-center mt-5 mt-md-0">
                <img loading="lazy" [src]="'/home/travel-responsibl.webp' | linkPrefix" class="w-100 cover" alt="">
                <h3 class="fsize-20 my-4 py-2 TTRegular-font light-black " data-aos="fade-up">Travel Responsibly</h3>
                <p class="futura-book-font fsize-16 light-black my-4 px-md-0 px-3" data-aos="fade-up">Cut the carbon
                    footprint when you <br class="d-none d-md-block"> ride on our specially designed e-bikes. <br
                        class="d-none d-md-block"> Give it a shot.</p>
            </div>
            <div>
                <img loading="lazy" [src]="'/home/trangle.webp' | linkPrefix"
                    class="triangle-img d-none d-md-inline position-relative" alt="">
            </div>
        </div>
    </div>
    <div class="remove-space">
        <div class="row justify-content-center position-relative  reach-us my-5 py-2">
            <div class="text-center">
                <p class="TTRegular-font fsize-18 light-black">CAN'T FIND IT</p>
                <h3 class="font-50 titlefont" data-aos="zoom-in" data-aos-duration="800">reach us</h3>
            </div>
            <div class="text-center mt-4 px-md-0 px-5   ">
                <p class="futura-book-font fsize-16 mb-4 mb-md-0">Don't worry. Just because it's not here, doesn't mean
                    we can't do. <br> Call and tell us what you're looking for. </p>
                <p class="TTRegular-font fsize-18 mt-md-4">WE'LL MAKE IT HAPPEN.</p>
            </div>
            <img loading="lazy" [src]="'/home/foot-1.webp' | linkPrefix"
                class="position-absolute showtext foot foot-left  px-0 " alt="">
            <img loading="lazy" [src]="'/home/foot2.webp' | linkPrefix"
                class="position-absolute showtext foot foot-right  px-0 " alt="">
        </div>
    </div>
    <br/><br /><br />
    <app-footer></app-footer>
</div>
<p-toast></p-toast>